import { template as template_283bdec33e1d45be8a8af03622cd28da } from "@ember/template-compiler";
const FKLabel = template_283bdec33e1d45be8a8af03622cd28da(`
  <label for={{@fieldId}} ...attributes>
    {{yield}}
  </label>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKLabel;
