import { template as template_b397f5463dea4817b1f97d59b4f6c758 } from "@ember/template-compiler";
const FKControlMenuContainer = template_b397f5463dea4817b1f97d59b4f6c758(`
  <li class="form-kit__control-menu-container">
    {{yield}}
  </li>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKControlMenuContainer;
