import { template as template_ebef72020fa0485a91a2cb9cf8e0f86b } from "@ember/template-compiler";
import SortableColumn from "./sortable-column";
const ActivityCell = template_ebef72020fa0485a91a2cb9cf8e0f86b(`
  <SortableColumn
    @sortable={{@sortable}}
    @number="true"
    @order="activity"
    @activeOrder={{@activeOrder}}
    @changeSort={{@changeSort}}
    @ascending={{@ascending}}
    @name="activity"
  />
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default ActivityCell;
